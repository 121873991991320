<script setup>
</script>

<template>
  <crud-details-page
    api="achievements/achievement-type-config"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
        <!-- Type -->
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.AchievementType"
          required
        />
        <crud-field-switcher api-field-name="IsBitValue" />
        <!-- DelayNotification -->
        <crud-field-switcher api-field-name="DelayNotification" />
      </fields-col>
      <fields-col :sm="8">
        <!-- AssetIcon -->
        <crud-field-selector-files api-field-name="AssetIcon" />
        <!-- NameTag -->
        <crud-field-selector-client-strings api-field-name="NameTag" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
