<template>
  <crud-details-page
    api="settings/incubatormessage"
  >
    <template #form>
      <fields-col :sm="8">
        <crud-field-number api-field-name="BattleNo" />
        <crud-field-number
          api-field-name="SendTime"
          min="-1000000"
          max="1000000"
          label="Send time [sec] (negative: before skirmish, positive: after)"
        />
        <crud-field-switcher api-field-name="HasPushNotification" />
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.IncubatorMessageType"
        />
        <crud-field-number
          api-field-name="SenderNo"
          min="0"
          max="2"
          label="0:GM (pinned on chat), 1:Deputy1, 2:Deputy2"
        />
      </fields-col>

      <section-divider>Text</section-divider>
      <fields-col :sm="24">
        <crud-field-selector-client-strings api-field-name="Message" />
      </fields-col>
      <fields-col :sm="24">
        <crud-field-text
          api-field-name="Str"
          label="Message (do testów tymczowy text, potem będzie użyty client string)"
        />
      </fields-col>

      <section-divider>Or conditional text</section-divider>
      <fields-col :sm="12">
        <crud-field-text
          api-field-name="StrWin"
          label="StrWin temporary"
        />
      </fields-col>
      <fields-col :sm="12">
        <crud-field-text
          api-field-name="StrLose"
          label="StrLose temporary"
        />
      </fields-col>
      <fields-col :sm="12">
        <crud-field-selector-client-strings api-field-name="MessageWin" />
      </fields-col>
      <fields-col :sm="12">
        <crud-field-selector-client-strings api-field-name="MessageLose" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
